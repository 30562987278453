.slidink {
  color: #16213e;
  overflow: hidden;
  background: linear-gradient(to right, #0f4c75, #0f4c75 50%, #16213e 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
  padding-bottom: 2px;
}

.slidink:hover,
.slidink:active {
  background-position: 0 100%;
}
