@import-normalize;
html,
body {
  height: 100%;
}

body {
  margin: 0;
  overflow: hidden;
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 64px);
  opacity: 0.3;
  z-index: -1;
}

body#sapo-tahu {
  background-image: url("./assets/images/wire-bg-cropped.webp"),
    url("./assets/images/wire-bg-cropped.png");
  background-repeat: repeat;
}

div#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
