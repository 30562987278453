.pageGridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: auto;
  padding: 0 5rem;
  max-width: 1920px;
}

.pageGridItem {
  padding: 1rem 2rem;
  position: relative;
}

.pageGridItem::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  z-index: -1;
  background-image: radial-gradient(
    circle farthest-corner at 1.3% 2.8%,
    rgba(239, 249, 249, 1) 0%,
    rgba(182, 199, 226, 1) 100.2%
  );
}

.pageGridItem.item1 {
  grid-row-start: 1;
  grid-column-start: 1;
  left: 5rem;
}

.pageGridItem.item2 {
  grid-row-start: 2;
  grid-column-start: 2;
  right: 5rem;
}

.pageGridItem.item3 {
  grid-row-start: 3;
  grid-column-start: 1;
  left: 5rem;
}

.pageGridItem.item4 {
  grid-row-start: 4;
  grid-column-start: 2;
  right: 5rem;
}

.myName {
  display: inline-block;
}

@media (max-width: 1920px) {
  .pageGridContainer {
    margin: auto 0;
  }
}

@media (max-width: 800px) {
  .pageGridContainer {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .pageGridItem {
    position: static;
  }

  .pageGridItem::before {
    background: none;
  }

  .pageGridItem.item1,
  .pageGridItem.item2,
  .pageGridItem.item3,
  .pageGridItem.item4 {
    grid-row: auto;
    grid-column: auto;
  }
}
